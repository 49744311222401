<template>
    <div class="m-5">
        <NavBar />
        <h1>Aufgussverwaltung</h1>
        <Button label="Neuen Aufguss hinzufügen" @click="prepareNewInfusion()" />
        <Button label="Zur Aufgussliste" @click="goToInfusionList()" class="ml-2"/>

        <Dialog header=Aufgussdetails v-model:visible="showInfusionDialog" @hide="resetSelectedInfusion()">
            <div class="justify-content-center" style="margin-top: 25px;">
                <FloatLabel class="mb-5">
                    <InputText v-model="selectedInfusion.name" required style="min-width: 15rem;" />
                    <label>Name</label>
                </FloatLabel>
                <FloatLabel class="mb-5">
                    <Calendar v-model="selectedInfusion.date" inputId="infusion_date" showIcon iconDisplay="input"
                        dateFormat="dd/mm/yy" :min-date="today" />
                    <label for="infusion_date">Datum</label>
                </FloatLabel>
                <div class="flex gap-1">
                    <div class="flex flex-column mb-5">
                        <label class="custom-label">Startzeit</label>
                        <InputText v-model="selectedInfusion.starttime" type="time" required />
                    </div>
                </div>
                <FloatLabel class="mb-5">
                    <Textarea v-model="selectedInfusion.info" rows="7" cols="100" />
                    <label>Info</label>
                </FloatLabel>
            </div>
            <template #footer>
                <Button label="Abbrechen" @click="showInfusionDialog = false" />
                <Button v-if="newInfusion" label="Hinzufügen" @click="addInfusion()" icon="pi pi-check"
                    severity="success" />
                <Button v-else label="Speichern" @click="updateInfusion()" icon="pi pi-check" severity="success" />
            </template>
        </Dialog>

        <ConfirmPopup></ConfirmPopup>
        <DataTable v-model:filters="filters" :value="infusions" :paginator="true" :rows="10" dataKey="id">
            <template #header>
                <div class="flex justify-content-end">
                    <IconField iconPosition="left">
                        <InputIcon>
                            <i class="pi pi-search" />
                        </InputIcon>
                        <InputText v-model="filters['global'].value" placeholder="Stichwortsuche" />
                    </IconField>
                </div>
            </template>
            <template #empty> Keine Aufgüsse gefunden. </template>
            <Column field="name" header="Name" :showFilterMenu="false">
                <template #body="{ data }">
                    {{ data.name }}
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter"
                        placeholder="Suche" />
                </template>
            </Column>
            <Column field="date" header="Datum" :showFilterMenu="false">
                <template #body="{ data }">
                    {{ data.date }}
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="weekdays"
                        placeholder="Filter" class="p-column-filter" :showClear="true">
                    </Dropdown>
                </template>
            </Column>
            <Column field="starttime" header="Uhrzeit" />
            <Column field="info" header="Infos" />
            <!-- <Column field="Kursinfo" header="Kursinfo" /> -->
            <Column header="Aktionen">
                <template #body="{ data }">
                    <div class="flex gap-1">
                        <Button label="Bearbeiten" icon="pi pi-pencil" @click="editInfusion(data)" />
                        <Button label="Löschen" icon="pi pi-trash" @click="confirm1($event, data.id)" />
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>



<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import Dropdown from 'primevue/dropdown';
import FloatLabel from 'primevue/floatlabel';
import Textarea from 'primevue/textarea';
import Calendar from 'primevue/calendar';
import ConfirmPopup from 'primevue/confirmpopup';
import { useConfirm } from 'primevue/useconfirm';
import { FilterMatchMode } from 'primevue/api';
import axios from 'axios';
import NavBar from '../components/NavBar.vue';
import { BACKEND_BASE_URL } from '@/config';


export default {
    components: {
        DataTable,
        Column,
        Button,
        Dialog,
        InputText,
        IconField,
        InputIcon,
        Dropdown,
        FloatLabel,
        Textarea,
        Calendar,
        ConfirmPopup,
        NavBar,
    },
    setup() {
        const confirm = useConfirm();
        return { confirm };
    },
    data() {
        return {
            infusions: [],
            selectedInfusion: {
                name: '',
                date: '',
                starttime: '',
                info: '',
            },
            today: (() => {
                const now = new Date();
                now.setHours(4);
                return now;
            })(),
            showInfusionDialog: false,
            newInfusion: false,
            infusionDialogHeader: '',
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
        };
    },
    methods: {
        async fetchInfusions() {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${BACKEND_BASE_URL}/api/infusions.php`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Cache-Control': 'no-cache',
                    },
                });
                this.infusions = response.data;
                console.log(this.infusions);
            } catch (error) {
                if (error.response?.status === 402) {
                    console.error('Login ist abgelaufen. Bitte loggen Sie sich erneut ein.');
                    this.$router.push('/admin/login');
                } else {   
                    console.error('Fehler beim Abrufen der Aufgüsse: ', error);
                }
            }
        },
        async addInfusion() {
            try {
                this.showInfusionDialog = false;
                const token = localStorage.getItem('token');
                this.selectedInfusion.date.setHours(this.selectedInfusion.date.getHours() + 4);
                this.selectedInfusion.date = this.selectedInfusion.date.toISOString().split('T')[0];
                console.log(this.selectedInfusion);
                await axios.post(`${BACKEND_BASE_URL}/api/infusions.php`, this.selectedInfusion, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Cache-Control': 'no-cache',
                    },
                });
                this.selectedInfusion = this.resetSelectedInfusion();
                await this.fetchInfusions();
            } catch (error) {
                if (error.response?.status === 402) {
                    console.error('Login ist abgelaufen. Bitte loggen Sie sich erneut ein.');
                    this.$router.push('/admin/login');
                } else {   
                    console.error('Fehler beim Hinzufügen des Aufgusses: ', error);
                }
            }
        },
        async deleteInfusion(id) {
            try {
                const token = localStorage.getItem('token');
                await axios.delete(`${BACKEND_BASE_URL}/api/infusions.php`, {
                    data: { id },
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Cache-Control': 'no-cache',
                    },
                });
                await this.fetchInfusions();
            } catch (error) {
                if (error.response?.status === 402) {
                    console.error('Login ist abgelaufen. Bitte loggen Sie sich erneut ein.');
                    this.$router.push('/admin/login');
                } else {   
                    console.error('Fehler beim Löschen des Aufgusses: ', error);
                }
            }
        },
        async updateInfusion() {
            try {
                this.showInfusionDialog = false;
                this.selectedInfusion.date.setHours(this.selectedInfusion.date.getHours() + 4);
                this.selectedInfusion.date = this.selectedInfusion.date.toISOString().split('T')[0];
                console.log(this.selectedInfusion);
                const token = localStorage.getItem('token');
                await axios.put(`${BACKEND_BASE_URL}/api/infusions.php`, this.selectedInfusion, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Cache-Control': 'no-cache',
                    },
                });
                this.selectedInfusion = this.resetSelectedInfusion();
                await this.fetchInfusions();
            } catch (error) {
                if (error.response?.status === 402) {
                    console.error('Login ist abgelaufen. Bitte loggen Sie sich erneut ein.');
                    this.$router.push('/admin/login');
                } else {   
                    console.error('Fehler beim Aktualisieren des Aufgusses: ', error);
                }
            }
        },
        prepareNewInfusion() {
            this.resetSelectedInfusion();
            this.newInfusion = true;
            this.showInfusionDialog = true;
        },
        editInfusion(infusion) {
            this.selectedInfusion = { ...infusion };
            const date = new Date(infusion.date);
            date.setHours(date.getHours() + 4);
            this.selectedInfusion.date = date;
            this.newInfusion = false;
            this.showInfusionDialog = true;
        },
        resetSelectedInfusion() {
            this.selectedInfusion = {
                name: 'Aufguss',
                date: this.today,
                starttime: '',
                info: ''
            };
        },
        confirm1(event, id) {
            this.$confirm.require({
                target: event.currentTarget,
                message: 'Wollen Sie den Aufguss wirklich löschen?',
                icon: 'pi pi-exclamation-triangle',
                rejectClass: 'p-button-secondary p-button-outlined p-button-sm',
                acceptClass: 'p-button-sm p-button-danger',
                rejectLabel: 'Abbrechen',
                acceptLabel: 'Löschen',
                accept: () => {
                    this.deleteInfusion(id);
                },
                reject: () => {
                }
            });
        },
        goToInfusionList() {
            this.$router.push('/sauna');
        },
    },
    mounted() {
        this.fetchInfusions();
    },
};
</script>

<style scoped>
.custom-label {
    color: #64748b;
    left: 0.75rem;
    top: -0.5rem;
    position: relative;
    font-size: 12px;
}
</style>
