<template>
  <HomescreenShortcut :visible="dialogVisible" @update:visible="dialogVisible = $event" />
  <h2>Aktueller Kursplan</h2>
  <div style="text-align: center;">
    <Dropdown v-model="selectedDay" :options="days" optionLabel="name" placeholder="Wähle einen Tag"
      class="custom-dropdown" :input-style="dropdownStyle" />
  </div>
  <div class="container-kurse">
    <div class="no-kurse-message" v-if="kurse.length === 0">Es finden heute keine Kurse statt.</div>
    <div v-else>
      <div v-for="kurs in kurse" :key="kurs.id">
        <KursCard :timeStart="kurs.Startzeit" :timeEnd="kurs.Endzeit" :name="kurs.Kursname"
          :cancelled="kurs.abgesagt === 1" :info="kurs.Kursinfo" />
      </div>
    </div>
  </div>
  <Button class="fixed-button" label="WebApp" icon="pi pi-desktop" rounded outlined @click="dialogVisible = true" />

  <Dialog v-model:visible="showNewsDialog" class="max-w-30rem">
    <template #header>
      <div class="block w-full">
        <p class="mt-0 mb-0 font-bold text-4xl">Neuigkeiten</p>
        <Divider />
      </div>
    </template>
    <div class="text-center">
      <p style="font-size: 1.3rem; line-height: 1.5rem;">Liebe Mitglieder, Am 27. November ab 16 Uhr bis Donnerstag 28. November ist der Kraftgerätebereich wegen der
        Anlieferung der neuen Geräte geschlossen. Wir haben Euch für diesen Zeitraum einen tollen Kursplan als
        Ausweichtraining zusammengestellt.</p>
    </div>
    <Button label="zum Ausweichplan" @click="redirectToExternalLink()" />

  </Dialog>
</template>

<script setup>
import { ref, watch, onMounted, onUnmounted } from "vue";
import { BACKEND_BASE_URL } from "@/config";

const dropdownStyle = ref({});
const updateStyle = () => {
  if (window.innerWidth > 768) {
    dropdownStyle.value = { 'font-size': '2.5rem', color: 'black' };
  } else {
    dropdownStyle.value = { 'font-size': '1.5rem', color: 'black' };
  }
};
onMounted(() => {
  updateStyle();
  window.addEventListener('resize', updateStyle);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateStyle);
});

function getFormattedDays() {
  const daysOfWeek = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
  const days = [];
  const today = new Date();

  for (let i = 0; i < 7; i++) {
    const currentDay = new Date(today.getTime() + i * 24 * 60 * 60 * 1000);
    const weekday = daysOfWeek[currentDay.getDay()];
    const day = String(currentDay.getDate()).padStart(2, '0'); // Padding für den Tag
    const month = String(currentDay.getMonth() + 1).padStart(2, '0'); // Padding für den Monat
    const year = currentDay.getFullYear();
    const formattedDate = `${weekday} - ${day}.${month}.${year}`;
    const date = `${year}-${month}-${day}`;
    days.push({ name: formattedDate, code: i, date: date });
  }
  return days;
}

const days = ref(getFormattedDays());
const selectedDay = ref(days.value[0]); // Standardmäßig den heutigen Tag vorauswählen
const kurse = ref([]); // Für die abgerufenen Kurse

// API-Aufruf, um die Kurse für den ausgewählten Tag zu laden
const fetchKurse = (date) => {
  const url = `${BACKEND_BASE_URL}/api/getKurse.php?date=${date}`;
  console.log("requesting Kurse at: ", url);
  fetch(url, {
    method: 'GET',
    cache: 'no-store'
  })
    .then(response => {
      console.log('Response:', response); // Protokolliere die Rohantwort
      return response.text(); // Zuerst als Text einlesen
    })
    .then(text => {
      console.log('Response text:', text); // Protokolliere den Text
      return JSON.parse(text); // Versuche, den Text in JSON zu parsen
    })
    .then(data => {
      if (('error' in data)) {
        kurse.value = [];
        console.error('Fehler beim Laden der Kurse:', data.error);
      } else if ('warning' in data) {
        kurse.value = [];
        console.log('Es konnten keine Kurse geladen werden:', data.warning);
      } else {
        kurse.value = data; // Aktualisiere die Kursdaten
      }
    })
    .catch(error => console.error('Fehler beim Laden der Kurse:', error));
};
// Watcher, um auf Änderungen von selectedDay zu reagieren
watch(selectedDay, (newDay) => {
  fetchKurse(newDay.date); // Hole Kurse für den neu ausgewählten Tag
});

// Initialer API-Aufruf, um Kurse für den Standard-Tag zu laden
fetchKurse(selectedDay.value.date);

const dialogVisible = ref(false);
const showNewsDialog = ref(false);

function redirectToExternalLink() {
  window.open("https://fitness-liebich.de/wp-content/uploads/2024/11/fitnessliebich_ausweichtermine_kurse.pdf", "_blank");
}

</script>

<script>
import KursCard from '@/components/KursCard.vue';
import Dropdown from 'primevue/dropdown';
import HomescreenShortcut from "@/components/HomescreenShortcut.vue";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";

export default {
  name: 'CourseView',
  components: {
    KursCard,
    Dropdown,
    HomescreenShortcut,
    Button,
    Dialog,
    Divider,
  }
}
</script>

<style scoped>
h2 {
  font-size: 40px;
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: center;
}

.custom-dropdown {
  width: 100%;
  border-left: none;
  border-right: none;
  border-radius: 0;
  font-size: 3rem;
  color: black;
}

.custom-dropdown .p-dropdown-label {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}

.custom-dropdown .p-dropdown-trigger {
  padding-right: 10px;
}

.p-dropdown-items-wrapper {
  max-height: 400px !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  font-size: 1, 5rem;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  padding-left: 15px;
  text-align: center;
}

.p-highlight {
  background-color: #d9fcf9;
}

.container-kurse {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 150px;
}

.no-kurse-message {
  font-size: 20px;
  padding-top: 20px;
  text-align: center;
}

.fixed-button {
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  left: auto;
}

@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) {
  h2 {
    font-size: 30px;
  }

  .custom-dropdown .p-dropdown-label {
    font-size: 23px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .container-kurse {
    padding-bottom: 100px;
  }

  .fixed-button {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
  }
}
</style>