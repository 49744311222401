<template>
    <div class="flex flex-wrap justify-content-center gap-5 mt-5">
        <Card style="width: 25rem; overflow: hidden">
            <template #header>
                <img class="header_img" alt="Kursplan"
                    src="../assets/fitness-liebich-lobbach-fitnesskurse-kachel-kursplan.jpg" />
            </template>
            <template #title>Aktueller Kursplan</template>
            <template #content>
                <p class="m-0">
                    Hier finden Sie den digitalen Kursplan von Fitness Liebich. Alle Kurse finden in der kleinen Sporthalle statt.
                </p>
            </template>
            <template #footer>
                <div class="flex gap-3 mt-1">
                    <Button label="Zum digitalen Kursplan" class="w-full"
                        @click="router.push({ name: 'CourseView' })" />
                </div>
            </template>
        </Card>

        <Card style="width: 25rem; overflow: hidden">
            <template #header>
                <img class="header_img" alt="Kursplan"
                    src="../assets/fitness-liebich-lobbach-wellness-kachel-sauna-aufgusszeiten.jpg" />
            </template>
            <template #title>Heutiger Aufgussplan</template>
            <template #content>
                <p class="m-0">
                    Hier finden Sie den tagesaktuellen Aufgussplan in unserer finnischen Sauna von Fitness Liebich. <br> <br>
                </p>
            </template>
            <template #footer>
                <div class="flex gap-3 mt-1">
                    <Button label="Zum digitalen Aufgussplan" class="w-full"
                        @click="router.push({ name: 'SaunaView' })" />
                </div>
            </template>
        </Card>
    </div>
</template>

<script setup>
import Card from 'primevue/card';
import Button from 'primevue/button';
import { useRouter } from 'vue-router';

const router = useRouter();

</script>

<style scoped>
.header_img {
    width: 100%;
}

@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) {
    h2 {
        font-size: 30px;
    }
}
</style>