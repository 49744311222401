<template>
    <div class="m-5">
        <NavBar />
        <h1>Kursverwaltung</h1>
        <Button label="Neuen Kurs hinzufügen" @click="showCourseDialog = true" />
        <Button label="Zur Kursliste" @click="goToList()" class="ml-2"/>

        <Dialog header="Neuen Kurs hinzufügen" v-model:visible="showCourseDialog" @hide="resetNewCourse">
            <div class="justify-content-center" style="margin-top: 25px;">
                <FloatLabel class="mb-5">
                    <InputText v-model="newCourse.Kursname" required style="min-width: 15rem;" />
                    <label>Kursname</label>
                </FloatLabel>
                <FloatLabel class="mb-5">
                    <Dropdown v-model="newCourse.Wochentag" :options="weekdays" style="min-width: 15rem;" />
                    <label>Wochentag</label>
                </FloatLabel>
                <div class="flex gap-1">
                    <div class="flex flex-column mb-5">
                        <label class="custom-label">Startzeit</label>
                        <InputText v-model="newCourse.Startzeit" type="time" required />
                    </div>
                    <div class="flex flex-column mb-5">
                        <label class="custom-label">Endzeit</label>
                        <InputText v-model="newCourse.Endzeit" type="time" required />
                    </div>
                </div>
                <FloatLabel class="mb-5">
                    <Textarea v-model="newCourse.Kursinfo" rows="7" cols="100" />
                    <label>Kursinfo</label>
                </FloatLabel>
            </div>
            <template #footer>
                <Button label="Abbrechen" @click="showCourseDialog = false" />
                <Button label="Hinzufügen" @click="addCourse" icon="pi pi-check" severity="success" />
            </template>
        </Dialog>

        <ConfirmPopup></ConfirmPopup>
        <DataTable v-model:filters="filters" :value="courses" :paginator="true" :rows="10" dataKey="id"
            filterDisplay="row" :globalFilterFields="['Kursname', 'Wochentag']">
            <template #header>
                <div class="flex justify-content-end">
                    <IconField iconPosition="left">
                        <InputIcon>
                            <i class="pi pi-search" />
                        </InputIcon>
                        <InputText v-model="filters['global'].value" placeholder="Stichwortsuche" />
                    </IconField>
                </div>
            </template>
            <template #empty> Keine Kurse gefunden. </template>
            <Column field="Kursname" header="Kursname" :showFilterMenu="false">
                <template #body="{ data }">
                    {{ data.Kursname }}
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter"
                        placeholder="Suche" />
                </template>
            </Column>
            <Column field="Wochentag" header="Wochentag" :showFilterMenu="false">
                <template #body="{ data }">
                    {{ data.Wochentag }}
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="weekdays"
                        placeholder="Filter" class="p-column-filter" :showClear="true">
                    </Dropdown>
                </template>
            </Column>
            <Column field="Startzeit" header="Startzeit" class="text-center" />
            <Column field="Endzeit" header="Endzeit" class="text-center" />
            <!-- <Column field="Kursinfo" header="Kursinfo" /> -->
            <Column header="Aktionen">
                <template #body="{ data }">
                    <div class="flex gap-1">
                        <Button label="Bearbeiten" icon="pi pi-pencil" @click="editCourse(data)" />
                        <Button label="Löschen" icon="pi pi-trash" @click="confirm1($event, data.id)" />
                        <Button label="Absagen" icon="pi pi-times" @click="cancelCourse(data)" />
                    </div>
                </template>
            </Column>
        </DataTable>

        <Dialog header="Kurs bearbeiten" v-model:visible="showEditDialog">
            <div class="justify-content-center" style="margin-top: 25px;">
                <FloatLabel class="mb-5">
                    <InputText v-model="selectedCourse.Kursname" required />
                    <label>Kursname</label>
                </FloatLabel>
                <FloatLabel class="mb-5">
                    <Dropdown v-model="selectedCourse.Wochentag" :options="weekdays" />
                    <label>Wochentag</label>
                </FloatLabel>
                <div class="flex gap-1">
                    <FloatLabel class="mb-5">
                        <InputText v-model="selectedCourse.Startzeit" type="time" required />
                        <label>Startzeit</label>
                    </FloatLabel>
                    <FloatLabel class="mb-5">
                        <InputText v-model="selectedCourse.Endzeit" type="time" required />
                        <label>Endzeit</label>
                    </FloatLabel>
                </div>
                <FloatLabel class="mb-5">
                    <Textarea v-model="selectedCourse.Kursinfo" rows="7" cols="100" />
                    <label>Kursinfo</label>
                </FloatLabel>
            </div>
            <template #footer>
                <Button label="Abbrechen" @click="showEditDialog = false" />
                <Button label="Speichern" @click="updateCourse" icon="pi pi-check" severity="success" />
            </template>
        </Dialog>

        <Dialog header="Kurs absagen" v-model:visible="showCancelDialog" @hide="datesToCancel.value = []">
            <div class="flex justify-content-left mt-5 mb-5">
                <FloatLabel class="width-100">
                    <Calendar v-model="datesToCancel.value" inputId="cancel_dates" selectionMode="multiple"
                        :manualInput="true" :disabledDates="disabledDates" showIcon showButtonBar iconDisplay="input"
                        dateFormat="dd/mm/yy">
                        <template #date="slotProps">
                            <div v-if="!isSelectedCourseday(slotProps.date)" class="p-2"
                                style="text-decoration: line-through; opacity: 0.5;">{{ slotProps.date.day }}</div>
                            <strong v-else>{{ slotProps.date.day }}</strong>
                        </template>
                    </Calendar>
                    <label for="cancel_dates">Absagen</label>
                </FloatLabel>
            </div>
            <div class="flex justify-content-left gap-3">
                <Button label="Abbrechen" @click="showCancelDialog = false" />
                <Button label="Absagen speichern" @click="saveCancellation(selectedCourse.id, datesToCancel.value)"
                    icon="pi pi-check" severity="success" />
            </div>
            <div class="flex justify-content-center mb-5 mt-5">
                <FloatLabel class="width-100">
                    <Chips id="cancel_chips" class="width-100" v-model="cancelledDates.value" :showAddButton="false"
                        :disabled="cancelledDates.value.length === 0" @remove="onChipRemove" />
                    <label for="cancel_chips">Abgesagte Termine</label>
                </FloatLabel>
            </div>
        </Dialog>

    </div>
</template>



<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import Dropdown from 'primevue/dropdown';
import FloatLabel from 'primevue/floatlabel';
import Textarea from 'primevue/textarea';
import Calendar from 'primevue/calendar';
import Chips from 'primevue/chips';
import ConfirmPopup from 'primevue/confirmpopup';
import { useConfirm } from 'primevue/useconfirm';
import { FilterMatchMode } from 'primevue/api';
import axios from 'axios';
import { ref } from 'vue';
import NavBar from '../components/NavBar.vue';
import { BACKEND_BASE_URL } from '@/config';
import router from '@/router';

export default {
    components: {
        DataTable,
        Column,
        Button,
        Dialog,
        InputText,
        IconField,
        InputIcon,
        Dropdown,
        FloatLabel,
        Textarea,
        Calendar,
        Chips,
        ConfirmPopup,
        NavBar,
    },
    setup() {
        const confirm = useConfirm();
        return { confirm };
    },
    data() {
        return {
            courses: [],
            newCourse: {
                Kursname: '',
                Wochentag: '',
                Startzeit: '',
                Endzeit: '',
                Kursinfo: '',
            },
            selectedCourse: null,
            showCourseDialog: false,
            showEditDialog: false,
            showCancelDialog: false,
            datesToCancel: ref([]),
            absagen: [],
            cancelledDates: ref([]),
            disabledDates: ref([]),
            weekdays: [
                'Montag',
                'Dienstag',
                'Mittwoch',
                'Donnerstag',
                'Freitag',
                'Samstag',
                'Sonntag',
            ],
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                Kursname: { value: null, matchMode: FilterMatchMode.CONTAINS },
                Wochentag: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
        };
    },
    methods: {
        async fetchCourses() {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${BACKEND_BASE_URL}/api/courses.php`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Cache-Control': 'no-cache',
                    },
                });
                this.courses = response.data;
                console.log(this.courses);
            } catch (error) {
                if (error.response?.status === 402) {
                    console.error('Login ist abgelaufen. Bitte loggen Sie sich erneut ein.');
                    router.push('/admin/login');
                } else {   
                    console.error('Fehler beim Abrufen der Kurse:', error);
                }
            }
        },
        async addCourse() {
            try {
                const token = localStorage.getItem('token'); // Stelle sicher, dass der Token im Local Storage gespeichert ist
                await axios.post(`${BACKEND_BASE_URL}/api/courses.php`, this.newCourse, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Cache-Control': 'no-cache',
                    },
                });
                this.newCourse = { Kursname: '', Wochentag: '', Startzeit: '00:00', Endzeit: '00:00', Kursinfo: '' };
                this.showCourseDialog = false;
                await this.fetchCourses();
            } catch (error) {
                if (error.response?.status === 402) {
                    console.error('Login ist abgelaufen. Bitte loggen Sie sich erneut ein.');
                    router.push('/admin/login');
                } else {   
                    console.error('Fehler beim Hinzufügen des Kurses:', error);
                }
            }
        },
        async deleteCourse(id) {
            try {
                const token = localStorage.getItem('token'); // Stelle sicher, dass der Token im Local Storage gespeichert ist
                await axios.delete(`${BACKEND_BASE_URL}/api/courses.php`, {
                    data: { id },
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Cache-Control': 'no-cache',
                    },
                });
                await this.fetchCourses();
            } catch (error) {
                if (error.response?.status === 402) {
                    console.error('Login ist abgelaufen. Bitte loggen Sie sich erneut ein.');
                    router.push('/admin/login');
                } else {   
                    console.error('Fehler beim Löschen des Kurses:', error);
                }
            }
        },
        async updateCourse() {
            try {
                const token = localStorage.getItem('token');
                await axios.put(`${BACKEND_BASE_URL}/api/courses.php`, this.selectedCourse, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Cache-Control': 'no-cache',
                    },
                });
                this.showEditDialog = false;
                await this.fetchCourses();
            } catch (error) {
                if (error.response?.status === 402) {
                    console.error('Login ist abgelaufen. Bitte loggen Sie sich erneut ein.');
                    router.push('/admin/login');
                } else {   
                    console.error('Fehler beim Aktualisieren des Kurses:', error);
                }
            }
        },
        async fetchAllAbsagen() {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${BACKEND_BASE_URL}/api/cancellation.php`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Cache-Control': 'no-cache',
                    },
                });
                this.absagen = response.data;
            } catch (error) {
                if (error.response?.status === 402) {
                    console.error('Login ist abgelaufen. Bitte loggen Sie sich erneut ein.');
                    router.push('/admin/login');
                } else {   
                    console.error('Fehler beim Abrufen der Absagen:', error);
                }
            }
        },
        async fetchAbsagenForKurs(kurs_id) {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${BACKEND_BASE_URL}/api/cancellation.php?kurs_id=${kurs_id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Cache-Control': 'no-cache',
                    },
                });
                this.absagen = response.data;
                this.cancelledDates.value = this.absagen.map(item => item.datum);
            } catch (error) {
                if (error.response?.status === 402) {
                    console.error('Login ist abgelaufen. Bitte loggen Sie sich erneut ein.');
                    router.push('/admin/login');
                } else {   
                    console.error('Fehler beim Abrufen der Absagen für den Kurs:', error);
                }
            }
        },
        async addAbsagen(data_kurs_id, datenArray) {
            if (datenArray.length === 0) {
                console.error('Keine Daten zum Hinzufügen der Absagen.');
                return;
            }
            try {
                const token = localStorage.getItem('token');
                await axios.post(`${BACKEND_BASE_URL}/api/cancellation.php`, { kurs_id: data_kurs_id, daten: datenArray }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Cache-Control': 'no-cache',
                    },
                });
            } catch (error) {
                if (error.response?.status === 402) {
                    console.error('Login ist abgelaufen. Bitte loggen Sie sich erneut ein.');
                    router.push('/admin/login');
                } else {   
                    console.error('Fehler beim Hinzufügen der Absagen:', error);
                }
            }
        },
        async deleteAbsage(cancellation) {
            try {
                const id = cancellation.id;
                const token = localStorage.getItem('token');
                await axios.delete(`${BACKEND_BASE_URL}/api/cancellation.php`, {
                    data: { id },
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Cache-Control': 'no-cache',
                    },
                });
                this.$toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Die Absagen wurden gelöscht:\n' + cancellation.datum, icon: 'pi pi-check', life: 3000 });
                await this.fetchAllAbsagen();
            } catch (error) {
                if (error.response?.status === 402) {
                    console.error('Login ist abgelaufen. Bitte loggen Sie sich erneut ein.');
                    router.push('/admin/login');
                } else {   
                    console.error('Fehler beim Löschen der Absage:', error);
                }
            }
        },
        editCourse(course) {
            this.selectedCourse = { ...course };
            this.showEditDialog = true;
        },
        async cancelCourse(course) {
            this.selectedCourse = { ...course };
            await this.fetchAbsagenForKurs(this.selectedCourse.id);
            this.showCancelDialog = true;
        },
        resetNewCourse() {
            this.newCourse = { Kursname: '', Wochentag: '', Startzeit: '', Endzeit: '', Kursinfo: '' };
        },
        weekdayToDigit(weekday) {
            switch (weekday) {
                case 'Montag':
                    return 1;
                case 'Dienstag':
                    return 2;
                case 'Mittwoch':
                    return 3;
                case 'Donnerstag':
                    return 4;
                case 'Freitag':
                    return 5;
                case 'Samstag':
                    return 6;
                case 'Sonntag':
                    return 0;
                default:
                    return -1;
            }
        },
        isSelectedCourseday(requestedDate) {
            const date = new Date(Date.UTC(requestedDate.year, requestedDate.month, requestedDate.day));
            const weekday = date.getDay();
            const selectedWeekday = this.weekdayToDigit(this.selectedCourse.Wochentag);
            return weekday === selectedWeekday;
        },
        saveCancellation() {
            const formattedDates = this.datesToCancel.value.map(date => {
                const newDate = new Date(date);
                newDate.setHours(newDate.getHours() + 12);
                return newDate.toISOString().split('T')[0]; // Format: YYYY-MM-DD
            });
            // save only new dates
            const filteredDates = formattedDates.filter(item => !this.cancelledDates.value.includes(item));
            this.addAbsagen(this.selectedCourse.id, filteredDates);
            this.datesToCancel.value = [];
            this.showCancelDialog = false;
            this.$toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Die Absagen wurden erfolgreich gespeichert:\n' + filteredDates.join('\n'), icon: 'pi pi-check', life: 3000 });
        },
        async onChipRemove(removedItem) {
            const index = this.absagen.findIndex(item => item.datum === removedItem.value[0]);
            if (index !== -1) {
                await this.deleteAbsage(this.absagen[index]);
            }
            this.fetchAbsagenForKurs(this.selectedCourse.id);

        },
        confirm1(event, id) {
            this.$confirm.require({
                target: event.currentTarget,
                message: 'Wollen Sie den Kurs wirklich löschen?',
                icon: 'pi pi-exclamation-triangle',
                rejectClass: 'p-button-secondary p-button-outlined p-button-sm',
                acceptClass: 'p-button-sm p-button-danger',
                rejectLabel: 'Abbrechen',
                acceptLabel: 'Löschen',
                accept: () => {
                    this.deleteCourse(id);
                },
                reject: () => {
                }
            });
        },
        goToList() {
            this.$router.push('/kurse');
        },
    },
    mounted() {
        this.fetchCourses();
    },
};
</script>

<style scoped>
.custom-label {
    color: #64748b;
    left: 0.75rem;
    top: -0.5rem;
    position: relative;
    font-size: 12px;
}
</style>
